<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="providersStore.provider.d_licenseNumber + ' - ' +
      providersStore.provider.d_nameLicense" />

      <!--Function Bar-->
      <provider-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

          <!--Provider Tuition-->
          <v-col cols="12" sm="12">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-file-invoice-dollar</v-icon>
                <v-toolbar-title class="pl-2">Tuition</v-toolbar-title>

                <v-spacer />
                <provider-entry-tuition />
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="100%">
              <v-card-text>

                <v-row dense>
                  <v-col cols="12" sm="12" md="1">
                    <text-display :displayLabel="'Charges Overage'" :displayString="$_yesNo(providersStore.provider.f_overage)" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Overage Type'" :displayString="providersStore.provider.d_overageType" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="2">
                    <text-display :displayLabel="'Parent Fee'" :displayString="$_yesNo(providersStore.provider.f_parentFee)" />
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="12" sm="12">
            <tuition-list :key="providersStore.provider.id"/>
          </v-col>
        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ProviderFunctionBar from '@/components/ProviderFunctionBar';
import TuitionList from '@/components/TuitionList';
import TextDisplay from '@/components/TextDisplay';
import ProviderEntryTuition from '@/components/ProviderEntryTuition';

export default {
  name: 'ProviderTuition',
  mixins: [mixin],
  computed: {
    ...mapState({
      providersStore: (state) => state.providersStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
    TitleBar,
    TuitionList,
    ProviderFunctionBar,
    TextDisplay,
    ProviderEntryTuition,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
      this.$store.dispatch('progressStore/set', false);
	  },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
